<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="PROJECTS"
          :breadcrumb="[
           
            { label: 'Dashboard' },
            { label: 'Projects' },
            { label: 'Project View' },
          ]"
        >
        </banner>
        <div class="row" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <!-- pc responsive -->
            <div
              class="card custom-card d-none d-md-block d-xl-block"
              style="
                font-family: 'Poppins', sans-serif;
               
                color: #00364f;
              "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row" style="margin-left: 0px; margin-right: 0px">
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.date) }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ dateTime(project.dueDate) }}</strong></span
                    >
                  </div>
                  <!-- <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div> -->

                  <div class="col">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- mobile responsive -->
            <div
              class="card custom-card d-block d-sm-block d-xl-none d-md-none"
              style="
                font-family: 'Poppins', sans-serif;
      
                color: #00364f;
              "
            >
              <div class="card-body">
                <h4
                  style="
                    color: #00364f;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 700;
                  "
                >
                  <b
                    v-if="project.service"
                    style="
                      color: #00364f;
                      font-family: 'Poppins', sans-serif;
                      font-size: 1.2rem;
                    "
                  >
                    {{ project.service.name }}</b
                  >
                </h4>

                <strong> Modules: </strong>
                <span
                  style="text-transform: uppercase"
                  v-for="(module1, index) in serviceModules"
                  :key="index"
                  >{{ module1.moduleName }}, &nbsp;</span
                >
                <div class="row mt-1">
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Code</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><strong>{{ project.clientServiceNo }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Project Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.date) }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Phone No.</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.phone }}</strong></span
                    >
                  </div>

                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Client Email</b>
                    </h4>
                    <span
                      style="color: #00364f; font-family: 'Poppins', sans-serif"
                      v-if="project.client"
                      ><strong>{{ project.client.email }}</strong></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>&nbsp;&nbsp;Due Date</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{ dateTime(project.dueDate) }}</b></span
                    >
                  </div>
                  <!-- <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share</b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div> -->
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>CA Share </b>
                    </h4>
                    <span style="color: #00364f; font-family: 'Poppins', sans-serif"
                      ><b>{{
                        project.professionalFee + project.professionalFeeGst
                      }}</b></span
                    >
                  </div>
                  <div class="col-6">
                    <h4 style="color: #f21300; font-family: 'Poppins', sans-serif">
                      <b>Status </b>
                    </h4>

                    <p
                      v-if="project.status == 'pending'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: gray;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>New</b>
                    </p>
                    <p
                      v-if="project.status == 'assigned'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #349c9e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'completed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #3ab04b;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'processed'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ffaa00;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'hold'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #8f8d8e;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'rejected'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: black;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>

                    <p
                      v-if="project.status == 'cancelled'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #f50303;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                    <p
                      v-if="project.status == 'upgrade'"
                      class="btn btn-sm"
                      style="
                        width: 80px;
                        background-color: #ee0def;
                        color: white;
                        box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                        border-radius: 50px;
                        font-size: 10px;
                        padding: 3px 3px 3px 3px;
                        margin-top: 10px;
                      "
                    >
                      <b>{{ project.status }}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- end resposnive -->
          </div>
        </div>

        <div class="row" style="margin-top: -17.5px">
          <div class="col-xl-7 col-md-7 col-sm-7" style="padding-right: 8px">
            <div
              class="card custom-card"
              style="height: 100%; "
            >
              <div class="card-body pt-2">
                <h3 style="color: black">
                  <b>Message & Notification History</b>
                </h3>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">
                  <div
                    v-for="(comment, index) in comments"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;

                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <!-- <div class="col-xl-1 col-2">
                    
                    
                  </div> -->
                    <div class="col-xl-12">
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment.user, comment.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: #051eff" v-if="comment.user.role_id == 1">{{
                        comment.user.name
                      }} ({{ comment.user_type }})</b>
                      <b style="color: #0170b9" v-if="comment.user.role_id == 2">{{
                        comment.user.name
                      }} ({{ comment.user_type }})</b>
                      <b style="color: #f21000" v-if="comment.user.role_id == 3">{{
                        comment.user.name
                      }} ({{ comment.user_type }})</b>
                      <b style="color: #af05f2" v-if="comment.user.role_id == 8">{{
                        comment.user.name
                      }} ({{ comment.user_type }})</b>
                      &nbsp;

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment.time }}, {{ dateTime(comment.date) }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment.comment }}
                      </p>
                    </div>
                  </div>
                </div>

                <form @submit.prevent="sandMessagePublick('public')" v-if="project.status != 'completed'">
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id=""
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send &nbsp; <i
class=
"fi fi-rs-paper-plane"
></i></b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <h4 style="color: black">
                  <strong>Private Messeges</strong>
                </h4>


                <div class="form-check" style="margin:5px 5px 5px -20px">


                  <div class="row" v-if="project.status != 'completed'">
                    <div class="col-xl-12 col-md-12 col-12-12"   >
                          <select v-model="userValue" @change="privateTypeSand" class="form-select"
                            aria-label="Default select example">
                            <option selected disabled>Select...</option>
                            <option v-if="project.staff" :value="project.staff">{{ project.staff.code }} {{ project.staff.name }}
                              (Staff)</option>
                            <option :value="1">SUP0001 SuperAdmin (Super Admin)</option>
                          </select>
                    </div>
                  </div>


                </div>
                <div style="overflow-y: auto; height: 400px; overflow-x: none">

                  <div
                    v-for="(comment1, index) in commentsprivates"
                    :key="index"
                    class="row mb-1 radius"
                    style="
                      background-color: #f5f5f5;

                      border-radius: 10px;
                      padding: 10px 10px 10px 10px;
                    "
                  >
                    <!-- <div class="col-xl-1 col-2">
                    
                    
                  </div> -->
                    <div class="col-xl-12">
                      <img
                        style="width: 30; height: 30px; border-radius: 100%"
                        :src="findImgUsedr(comment1.user, comment1.user_type)"
                        alt=""
                      />
                      &nbsp; &nbsp;
                      <b style="color: #051eff" v-if="comment1.user.role_id == 1">{{
                        comment1.user.name
                      }} ({{ comment1.user_type }})</b>
                      <b style="color: #0170b9" v-if="comment1.user.role_id == 2">{{
                        comment1.user.name
                      }} ({{ comment1.user_type }})</b>
                      <b style="color: #f21000" v-if="comment1.user.role_id == 3">{{
                        comment1.user.name
                      }} ({{ comment1.user_type }})</b> 
                      <b style="color: #af05f2" v-if="comment1.user.role_id == 8">{{
                        comment1.user.name 
                      }} ({{ comment1.user_type }})</b> 
                      &nbsp;

                      <small style="color: #4c4c4c; text-transform: uppercase">
                        {{ comment1.time }}, {{ comment1.date }}</small
                      >

                      <p
                        style="
                          color: 000000;
                          font-size: 14px;
                          padding: 10px 10px 10px 10px;
                          text-align: justify;
                        "
                      >
                        {{ comment1.comment }}
                      </p>
                    </div>
                  </div>

                  
                </div>

                <form @submit.prevent="sandMessagePublick('private')" v-if="project.status != 'completed'">
                  <div class="row mt-1 mb-1">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                      <textarea
                      required
                        v-model="msgFromPublic.comment"
                        id=""
                        rows="4"
                        class="form-control"
                        placeholder="Type your Messege here"
                      ></textarea>
                      <div class="text-end">
                        <button
                          type="submit"
                          class="btn btn-sm mt-1 text-white"
                          style="background-color: #00364f; padding: 7px 50px 7px 50px"
                        >
                          <b>send &nbsp; <i
class=
"fi fi-rs-paper-plane"
></i></b>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-md-5 col-sm-5" style="padding-left: 8px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <!-- for Upgrade  -->
            <div
              class="card custom-card"  v-if="upgradesData.length != 0"
              style="padding: 0px 0px 0px 0px;"
           
            >
              <div class="card-body">
                <div class="row">
                  <span style="color: #00364f"><strong>Request Upgrade</strong></span>
                  <!-- <div class="col-xl-9 text-start">
                    
                  </div>
                  <div class="col-xl-3 text-end">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#upgradeModel"
                      class="btn btn-sm text-white"
                      style="background-color: #00364f; border-radius: 10px"
                    >
                      <strong>Request</strong>
                    </button>
                  </div> -->

                  <div class="col-12 pt-1">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm" style="font-size: 14px">
                        <tr
                          class="text tr-head table-wrapper-scroll-y rounded-circle"
                          style="background-color: white"
                        >
                          <th
                            class="text-truncate"
                            style="
                              background-color: #cdcdcd;
                              border-radius: 8px 0px 0px 8px;
                            "
                          >
                            Title
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Date
                          </th>
                          <th class="text-truncate" style="background-color: #cdcdcd">
                            Amount
                          </th>

                          <th
                            style="background-color: #cdcdcd"
                            class="text-truncate text-center"
                          >
                            Status
                          </th>
                          <th
                            style="
                              background-color: #cdcdcd;
                              border-radius: 0px 8px 8px 0px;
                            "
                            class="text-truncate text-end"
                          >
                            Action
                          </th>
                        </tr>

                        <tbody class="text">
                          <tr v-for="(upgrade, index) in upgradesData" :key="index">
                            <td class="text-truncate">{{ upgrade.title }}</td>
                            <td class="text-truncate">{{ dateTime(upgrade.date) }}</td>
                            <td class="text-truncate">Rs. {{ upgrade.amt }}</td>
                            <td class="text-truncate">
                              <span
                                v-if="upgrade.status == 'approved'"
                                style="
                                  width: 80px;
                                  background-color: #430194;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'paid'"
                                style="
                                  width: 80px;
                                  background-color: green;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                          
                              <span
                                v-if="upgrade.status == 'cancel'"
                                style="
                                  width: 80px;
                                  background-color: #f21000;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>

                              <span
                                v-if="upgrade.status == 'pending'"
                                style="
                                  width: 80px;
                                  background-color: orange;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                              <span
                                v-if="upgrade.status == 'reject'"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 10px 3px 10px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                {{ upgrade.status }}
                              </span>
                            </td>
                            <td class="text-truncate text-end">
                              <div
                                class="btn-group btn-group-sm"
                                role="group"
                                aria-label="..."
                              >
                                <span
                                  style="
                                    background-color: blue;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                  @click="
                                    openWindow(
                                      `${
                                        this.$store.state.imgUrl +
                                        '/clientservice/' +
                                        this.$route.params.id +
                                        '/' +
                                        upgrade.document
                                      }`
                                    )
                                  "
                                >
                                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                                </span>
                                <span
                                v-if="upgrade.status == 'approved'"
                                  style="
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                    background-color: #00364f;
                                  "
                                  @click="freshUpgreadeForm(upgrade)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upgradeModelSatff"
                                  class="btn btn-sm text-white "
                                  ><i class="fa fa-inr" aria-hidden="true"></i
                                ></span>
                                <!-- <span
                                  @click="upgradeDataAsign(upgrade)"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upgradeModel"
                                  style="
                                    background-color: green;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </span> -->
                                <!-- <span
                                  @click="upgradeDelete(upgrade)"
                                  style="
                                    background-color: red;
                                    font-size: 8pt;
                                    width: 25px;
                                    padding: 5px 5px 5px 5px;
                                  "
                                  class="btn btn-sm text-white"
                                >
                                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </span> -->
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- for Upgrade  -->
            <div v-if="project.staff" class="card custom-card"  :style="(upgradesData.length != 0) ? 'margin-top: -17.5px;' : ''">
              <div class="card-body">
                <div class="row radius" style="line-height: 1.3em; border-radius: 10px">
                  <div class="col">
                    <!-- mobile responsive -->
                    <img
                      class="d-xl-none d-md-none d-block"
                      style="
                        width: 100%;
                        height: 100%;
                        margin-left: -10px;
                        border-radius: 100%;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                    <!-- pc responsive -->
                    <img
                      class="d-xl-block d-md-block d-none"
                      style="
                        width: 80%;
                        height: 85%;
                        border-radius: 100%;
                        margin-left: -10px;
                      "
                      :src="findImgUsedr(project.staff, 'staff')"
                      alt=""
                    />
                  </div>
                  <div class="col-10">
                    <b style="color: #f21300">Vakilgiri Expert</b>
                    <div class="text">
                      <b>Name : </b>
                      <span v-if="project.staff"> {{ project.staff.name }}</span>
                    </div>
                    <div class="text">
                      <b>Contact Number : </b>
                      <span v-if="project.staff"> +91- {{ project.staff.phone }} </span>
                    </div>
                    <div class="text">
                      <b>Email : </b>
                      <span v-if="project.staff"> {{ project.staff.email }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

  
            <div
            
              class="card custom-card"
             
              :style="(project.staff) ? 'margin-top: -17.5px;' : ''"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-md-3 col-sm-3">
                    <b
                      class="font"
                      style="color: black; font-weight: 600; font-size: 16px"
                      >Tasks</b
                    >
                  </div>
                  <div class="col-xl-9 col-md-9 col-sm-9">
                    <div class="progress">
                      <div
                        id="progressBarProject"
                        class="progress-bar bg-danger"
                        role="progressbar"
                        aria-valuenow="20"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        v-if="Tasks.length != 0"
                        :style="`width: ${this.progressForProject()}%;`"
                      >
                        {{ taskProgress }}%
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div>
                      <div
                        class="form-check mt-1"
                        v-for="(task, index) in Tasks"
                        :key="index"
                      >
                        <input
                          v-if="task.status == 'complete'"
                          class="form-check-input"
                          type="checkbox"
                          disabled
                          checked
                        />
                        <input
                          v-else
                          class="form-check-input"
                          type="checkbox"
                          id="flexCheckDisabled"
                          disabled
                        />
                        <label style="color: #00364f; font-weight: 600">
                          <span v-if="task.step"> {{ task.step.title }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="requestdocuments.length != 0"
              class="card custom-card"
              style="margin-top: -17.5px;"
            >
              <div class="card-body font">
             
             

                <input
                  @change="atachmentUpload"
                  style="position: absolute; top: -150000px"
                  id="file-input2"
                  type="file"
                />

             




                <div class="row text">
                



                <div class="col-12">
                  <h4><b style="color: black">Requested Documents</b></h4>
                  <div class="container-fluid table-scroll">
                    <table class="table table-hover table-sm" style="font-size: 14px">
                      <tr
                        class="text tr-head table-wrapper-scroll-y rounded-circle"
                        style="background-color: white"
                      >
                        <th
                          class="text-truncate"
                          style="
                            background-color: #cdcdcd;
                            border-radius: 8px 0px 0px 8px;
                          "
                        >
                          +
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Title
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Doccument
                        </th>
                      

                        <th
                          style="
                            background-color: #cdcdcd;
                            border-radius: 0px 8px 8px 0px;
                          "
                          class="text-truncate text-end"
                        >
                        Remark
                        </th>
                      </tr>

                      <tbody class="text">
                        <tr  v-for="(clentDocc, index) in requestdocuments" :key="index">
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                              <i
                        class="fa fa-check"
                        aria-hidden="true"
                        style="color: green"
                      ></i>
                            </span>
                            <span v-else>
                              <font-awesome-icon icon="remove" style="color: red" />
                            </span>
                          
                          </td>
                          <td class="text-truncate">{{ clentDocc.title }}</td>
                          <td class="text-truncate">
                            <span v-if="clentDocc.status == 'complete'">
                            
                              
                              <span @click="openWindowClientDoccRequest(clentDocc)"
                        ><i class="fa fa-download" aria-hidden="true"></i></span
                    >
                  


                            </span>
                            <span v-else>
                      <label  @click="assignIDforReply(clentDocc)"  for="file-input2">
                          <img
                            style="width: 25px; height: 25px"
                            src="/assets/image/upload.png"
                          />
                        </label>
                    </span>
                          </td>
                          <td class="text-truncate">{{ clentDocc.description }}</td>
                         
                        
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              </div>
            </div>

            <!-- payment area -->
            <div class="card custom-card"  :style="(requestdocuments.length != 0) ? 'margin-top: -17.5px;' : ''">
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">CLIENT PAYMENT RECIPT</b></h4>
                </div>
                <div class="col-md-4 text-end">
          <div v-if="project.status != 'cancelled'">  <button
                    v-if="paidAmt == project.serviceCharge + project.serviceChargeGst"
                    type="button"
                    class="btn btns text-white"
                    style="background-color: green; padding-top: 5px; padding-bottom: 5px"
                  >
                    <i>Paid</i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    @click="valuenullPayment()"
                    data-bs-toggle="modal"
                    data-bs-target="#paymentReciptPay"
                  >
                  <strong><i class="fi fi-br-plus"></i></strong>
                  </button></div>

                </div>
              </div>
              <div class="card-body pt-1">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(recipt, index) in paymentRecipts"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b v-if="recipt.client">
                      {{ recipt.client.fname }} {{ recipt.client.lname }}</b
                    >
                    <div>
                      <small> {{ dateTime(recipt.date) }}</small>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block text-center">
                    <img
                      data-bs-toggle="modal"
                      data-bs-target="#paymentRecipt"
                      @click="paymentReciptAsign(recipt)"
                      style="width: 20px"
                      src="/assets/image/recipt.png"
                      alt="image2"
                    />
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <b class="d-xl-none d-md-none d-block" style="color: red">
                      <img
                        data-bs-toggle="modal"
                        data-bs-target="#paymentRecipt"
                        @click="paymentReciptAsign(recipt)"
                        style="width: 20px; margin-right: -5px"
                        src="/assets/image/recipt.png"
                        alt="image2"
                      />
                    </b>

                    <small> Rs.{{ recipt.amt }}</small>
                  </div>
                  <hr style="margin-top: -1px" />
                </div>
                <div class="row text">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-4">
                    <strong>Total: </strong>
                    <span style="color: #f21000">{{
                      project.serviceCharge + project.serviceChargeGst
                    }}</span>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-4 text-end">
                    <strong>Paid: </strong>
                    <span style="color: #f21000">Rs. {{ getAmtDue(project, "paid") }}</span>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-4 text-end">
                    <strong>Due: </strong>
                    <span style="color: #f21000">Rs. {{ getAmtDue(project, "due") }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- end payment area  -->

            <div class="card custom-card" >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">DOCS UPLOADED BY RETAILER</b></h4>
                </div>
                <div class="col-md-4 text-end">
             
 <button
 v-if="project.status == 'pending'"
                    @click="LoadDocuments(project.service)"
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#documentsByRetailer"
                  >
                    <strong><i class="fi fi-br-plus"></i></strong>
                  </button>
              
                </div>
              </div>
              <div class="card-body pt-1">
                <div v-if="project.client_service_document">
                  <div
                    class="row"
                    style="padding-top: 3px"
                    v-for="(docc, index) in project.client_service_document"
                    :key="index"
                  >
                    <div class="col-xl-4 col-md-4 col-6 text">
                      <b v-if="docc.document">{{ docc.caption }}</b>
                    </div>
                    <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block text-center">
                      <span
                      @click="
                      downloadImg(docc)
                      "
                      ><b style="color: red; cursor: pointer"><i class="fi fi-br-download"></i></b></span
                    >
                  &nbsp;  <span  v-if="project.status == 'pending'"><small @click="deleteDoccUploadByRetailer(docc)" style="color:#f21000;cursor: pointer"><i class="fi fi-br-trash"></i></small></span>
                    </div>
                    <div class="col-xl-4 col-md-4 col-6 text text-end">
                      <span class="d-xl-none d-md-none d-block">
  <b  @click="
                      downloadImg(docc)
                      " style="color: #f21000;cursor: pointer"
                        ><i class="fi fi-br-download"></i></b
                      >
                      &nbsp;  <span  v-if="project.status == 'pending'"><small  @click="deleteDoccUploadByRetailer(docc)" style="color:#f21000"><i class="fi fi-br-trash"></i></small></span>
                   
                      </span>
                    
                      <small>{{dateTimeWithTime(docc.updated_at)}}</small>
                    </div>
                    <hr style="margin-top: -1px" />
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="certificates.length != 0"
              class="card custom-card"
            
            >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4>
                    <b class="text-white">DOCS UPLOADED BY PROFESSIONAL</b>
                  </h4>
                </div>
                <div class="col-md-4 text-end">
                  <!-- <button
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#documentsByPro"
                  >
                    <b>Upload</b>
                  </button> -->
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 3px"
                  v-for="(certificate, index) in certificates"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text ">
                    <b>{{ certificate.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block text-center">
                    <span
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            certificate.certificate
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer"><i class="fi fi-br-download"></i></b></span
                    >
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <span class="d-xl-none d-md-none d-block" >
                      <span
                      @click="
                        openWindow(
                          `${
                            this.$store.state.imgUrl +
                            '/clientservice/' +
                            this.$route.params.id +
                            '/' +
                            certificate.certificate
                          }`
                        )
                      "
                      ><b style="color: red; cursor: pointer"><i class="fi fi-br-download"></i></b></span
                    >
                    
                        </span>
                   <div>
                    <small
                      >Date: {{ dateTime(certificate.date) }}
                     </small
                    >
                   </div>
<div>
  <small
                      >Exp : {{ dateTime(certificate.ExpireDate) }}
                     </small
                    >
</div>
                 
                  </div>
                  <hr style="margin-top: -1px" />
                </div>
              </div>
            </div>

            <div
              v-if="challans.length != 0"
              class="card custom-card d-none"
            
            >
              <div
                class="card-header"
                style="
                  background-color: #00364f;
                  border-radius: 10px 10px 0px 0px;
                  padding: 10px 10px 10px 10px;
                  margin-top: -17.5px;
                "
              >
                <div class="col-md-8 text-white">
                  <h4><b class="text-white">CHALANS</b></h4>
                </div>
                <div class="col-md-4 text-end">
                  <!-- <button
                    type="button"
                    class="btn btns text-white"
                    style="
                      background-color: #f21300;
                      padding-top: 5px;
                      padding-bottom: 5px;
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#challansModal"
                  >
                    <b>Upload</b>
                  </button> -->
                </div>
              </div>
              <div class="card-body">
                <div
                  class="row"
                  style="padding-top: 2px"
                  v-for="(challan, index) in challans"
                  :key="index"
                >
                  <div class="col-xl-4 col-md-4 col-6 text">
                    <b>{{ challan.title }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 d-none d-md-block d-xl-block">
                    <b class="d-xl-block d-md-block d-none">{{ challan.challanNo }}</b>
                  </div>
                  <div class="col-xl-4 col-md-4 col-6 text text-end">
                    <small>Amount : Rs.{{ challan.amt }}</small
                    ><br />
                    <small style="color: red; cursor: pointer"
                      ><b
                        ><span
                          @click="
                            openWindow(
                              `${
                                this.$store.state.imgUrl +
                                '/clientservice/' +
                                this.$route.params.id +
                                '/' +
                                challan.challan
                              }`
                            )
                          "
                          >Download</span
                        >
                        <!-- | -->
                        <!-- <span @click="deleteChallans(challan)">Delete</span> -->
                      </b></small
                    ><br />
                    <small class="d-xl-none d-md-none d-block">{{
                      challan.challanNo
                    }}</small>
                    <small>{{ dateTime(challan.date) }} </small>
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="card-footer text-white"
                style="
                  background-color: #00364f;
                  border-radius: 0px 0px 10px 10px;
                  padding: 8px 5px 8px 5px;
                "
              >
                <div class="row">
                  <div class="col-9 text-start"><b>Total Amount</b></div>
                  <!-- <div class="col-5 text-start">
                    <button class="btn btn-sm disabled" style="background-color: yellow">
                      Final Submit
                    </button>
                  </div> -->
                  <div class="col-3 text-end">
                    <b>Rs. {{ totalAmtChallan }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="documentsByPro"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">DOCS UPLOADED BY PROFESSIONAL</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createCertificateByPrfesional()">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="certificateForm.date"
                  placeholder="date"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="certificateForm.title"
                  placeholder="Title"
                />
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate No</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="certificateForm.certificateNo"
                  placeholder="Certificate No"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>certificate Athority</strong></label>

                <select
                  v-model="certificateForm.certificateAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
              </div> -->
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Certificate</strong></label>
                <!-- v-model="chalansForm.challan" -->
                <input
                  type="file"
                  class="form-control"
                  @change="UploadCertificate"
                  placeholder="challan"
                  id="certificate"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.expireDate"
                  placeholder="expireDate"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="certificateForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>

              <!--
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="upgradeModel"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Upgrade </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="upgradeEdit == 'false' ? createUpgrade() : updateUpgrade()"
          >
            <div class="row">
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="upgradeForm.title"
                  placeholder="Title"
                />
              </div>
              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Document</strong></label>
                <input
                  type="file"
                  class="form-control"
                  @change="uploadUpgrade"
                  placeholder="Title"
                  id="uploadUpgrade"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="upgradeForm.date"
                  placeholder="date"
                />
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div> -->

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="upgradeForm.amt"
                  placeholder="Amount"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="upgradeForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="documentsByRetailer"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">DOCS UPLOADED BY RETAILER</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6 p-1" v-for="(docc, index) in allDoccs" :key="index">
              <label>{{ docc.document }}</label>
              <input
              :id="`doccUpload${docc.id}`"
                type="file"
                class="form-control"
                @change="uploadDocuments($event, docc)"
              />
              <small v-if="docc.format">Format: {{ docc.format }}</small
              ><br />
              <small v-if="docc.size">size: {{ docc.size }} kb</small>
              <small v-if="docc.width">Format: {{ docc.width }}px</small><br />
              <small v-if="docc.height">size: {{ docc.height }}px</small>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21000"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #00364f"
          >
            <strong>Confirm</strong>
          </button> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="challansModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong>Challan </strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createChalans()">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.date"
                  placeholder="date"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Title</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.title"
                  placeholder="Title"
                />
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan No</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.challanNo"
                  placeholder="Challan No"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan Athority</strong></label>

                <select
                  v-model="chalansForm.challanAthority"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select</option>
                  <option value="MCA">MCA</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Income Tax">Income Tax</option>
                  <option value="TDS">TDS</option>
                  <option value="IEC Portal">IEC Portal</option>
                  <option value="draft">Draft</option>
                  <option value="NSDL">NSDL</option>
                  <option value="GST Portal">GST Portal</option>
                  <option value="FSSAI">FSSAI</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Amount</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.amt"
                  placeholder="Amount"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Challan</strong></label>
                <!-- v-model="chalansForm.challan" -->
                <input
                  type="file"
                  class="form-control"
                  @change="UploadChallan"
                  placeholder="challan"
                  id="challan"
                />
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Note</strong></label>

                <textarea
                  class="form-control"
                  v-model="chalansForm.note"
                  rows="3"
                  placeholder="Note"
                ></textarea>
              </div>

              <!-- <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Expire Date</strong></label>
                <input
                  type="date"
                  class="form-control"
                  v-model="chalansForm.expireDate"
                  placeholder="expireDate"
                />
              </div>
              <div class="col-xl-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong>Validity</strong></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="chalansForm.validity"
                  placeholder="validity"
                />
              </div> -->
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading == true">
    <Spinner />
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="upgradeModelSatff"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content modal-sm">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">Upgrade Verification</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">


          <h1>
           Rs. {{upgradeModelSatffVeryfiyForm.amt}} 
          </h1>

          <form @submit.prevent="upgradeModelSatffSaveData()">
            <div class="row">
              <div class="col-xl-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong style="color: #00364f">Select Status <small style="color:#f21000">*</small></strong></label>
                <select
                required
                  v-model="upgradeModelSatffVeryfiyForm.status"
                  style="padding: 5px 5px 5px 5px; height: 30px"
                  class="form-select"
                  aria-label="Default select example"
                >
                <option disabled selected>Select...</option>
                  <option value="paid">Paid</option>
                  <option value="cancel">Cancel</option>
                </select>
              </div>
              <div class="col-xl-12 col-12" style="padding: 5px 5px 5px 5px">
                <label><strong style="color: #00364f">Remark</strong></label>
                <textarea
                  v-model="upgradeModelSatffVeryfiyForm.remark"
                  rows="3"
                  class="form-control"
                  placeholder="Remark"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- model paymrnt -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="paymentRecipt"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Payment Recipt</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table style="width: 100%; text-transform: capitalize" class="font">
            <tr>
              <th class="alin-left">
                <strong class="heading" style="font-size: 14pt" v-if="userData"
                  >{{ userData.name }}
                </strong>
              </th>
              <th class="alin-right text-end">
                <!-- <strong class="heading">INVOICE</strong> -->
              </th>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>Address: </strong
                  ><span v-if="userData">{{ userData.address }} {{ userData.city }} <span v-if="userData.state">{{ userData.state.name }}</span> {{ userData.pin }}</span>
                </div>
              </td>
              <td class="alin-right text-end">
                <div style="font-size: 10pt; color: #00364f">
                  <strong># {{ project.clientServiceNo }}</strong>
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[M]: </strong
                  ><span v-if="userData"> +91-{{ userData.phone }}, </span>
                </div>
              </td>
              <td class="text-end">
                <div style="font-size: 10pt">
                  <span
                    style="
                      width: 100px;
                      background-color: green;
                      color: white;
                      box-shadow: 0px 0px 5px 0px gray;
                      border-radius: 50px;
                      font-size: 10px;
                      padding: 3px 10px 3px 10px;
                      margin-top: 10px;
                      margin-bottom: 5px;
                    "
                    >paid</span
                  >
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left">
                <div style="font-size: 10pt; color: #00364f">
                  <strong>[E]: </strong>
                  <span v-if="userData"> {{ userData.email }}, </span>
                </div>
              </td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f; padding-top: 10px">
                  <strong>BILL TO</strong>
                </div>
              </td>
            </tr>

            <tr>
              <th class="alin-left"><strong class="heading"> </strong></th>
              <th class="text-end" style="color: #00364f; margin-top: -5px">
                <strong class="heading" style="font-size: 14pt" v-if="project.client"
                  >{{ project.client.fname }} {{ project.client.lname }}</strong
                >
              </th>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f">
                  <strong v-if="project.business"> {{ project.business.name }}</strong>
                </div>
              </td>
            </tr>

            <tr>
              <td class="alin-left"></td>
              <td class="text-end">
                <div style="font-size: 10pt; color: #00364f" v-if="project.client">
                  {{ project.client.address }} {{ project.client.address2 }} {{ project.client.city }} <span v-if="project.client.state">{{ project.client.state.name }}</span> {{ project.client.pin }}
                </div>
              </td>
            </tr>
          </table>

          <hr />

          <table
            class="font"
            style="
              width: 100%;
              color: #00364f;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            <tr>
              <th class="alin-left" style="width: 10%">Date</th>
              <td class="alin-left">
                {{ dateTime(reciptData.date) }}
              </td>
              <th class="text-end">Total Amount</th>
              <td class="text-end" style="width: 12%">
                Rs.{{ project.serviceCharge + project.serviceChargeGst }}
              </td>
            </tr>

            <tr>
              <th class="alin-left text-truncate">Payment Mode:</th>
              <td class="alin-left">{{ reciptData.paymentMode }}</td>
              <th class="text-end">Paid Amount</th>

              <td class="text-end" style="width: 12%">Rs.{{ reciptData.amt }}</td>
            </tr>

            <!-- <tr v-if="reciptData.paymentMode == 'Online'">
              <th class="alin-left">Bank</th>
              <td class="alin-left">{{ reciptData.bank }}</td>
              <th class="text-end">Branch</th>

              <td class="text-end" style="width: 12%">{{ reciptData.branch }}</td>
            </tr>
            <tr v-if="reciptData.paymentMode == 'Online'">
              <th class="alin-left">IFSC</th>
              <td class="alin-left">{{ reciptData.ifsc }}</td>
              <th class="text-end">Account No</th>

              <td class="text-end" style="width: 12%">{{ reciptData.accountno }}</td>
            </tr> -->

            <tr v-if="reciptData.paymentMode == 'Cheque'">
              <th class="alin-left">Cheque No</th>
              <td class="alin-left">{{ reciptData.checkno }}</td>
              <th class="text-end"></th>

              <td class="text-end" style="width: 12%"></td>
            </tr>
          </table>
          <div v-if="reciptData.paymentMode == 'Online'">
          <Strong> Remark:</Strong>
          <div >
         {{ reciptData.notation }}
          </div>
          </div>

          <table
            class="font"
            style="width: 100%; margin-top: 10px; text-transform: capitalize"
          >
            <tr style="background-color: #00364f; font-size: 10pt; color: white">
              <th class="alin-left" style="padding: 5px 5px 5px 5px">Description</th>
              <th class="text-end" style="padding: 5px 5px 5px 5px">Rate</th>
              <th class="text-end" style="padding: 5px 5px 5px 5px">GST</th>
              <th class="text-end" style="padding: 5px 5px 5px 5px">Amount</th>
            </tr>
            <tr style="font-size: 10pt; color: #00364f">
              <td>
                <span v-if="project.service">{{ project.service.name }}</span>
                <span v-for="(mod, index) in serviceModules" :key="index"
                  ><span v-if="mod"> ({{ mod.moduleName }})</span></span
                >
              </td>
              <td class="text-end">Rs.{{ project.serviceCharge }}</td>
              <td class="text-end">Rs.{{ project.serviceChargeGst }}</td>
              <td class="text-end">
                Rs.{{ project.serviceCharge + project.serviceChargeGst }}
              </td>
            </tr>
          </table>

          <div
            style="
              background-color: #f2f2f2;
              padding: 5px 10px 5px 10px;
              border-radius: 10px;
              margin-top: 30px;
            "
          >
            <table
              style="
                width: 100%;
                font-size: 10pt;
                color: #00364f;
                text-transform: capitalize;
              "
              class="font"
            >
              <tr>
                <td class="text-start">
                  <strong>In words: {{ netAmtInWords }}</strong>
                </td>
                <td class="text-end">
                  <strong style="margin-right: 50px">Total</strong>
                  <strong>
                    Rs.{{ project.serviceCharge + project.serviceChargeGst }}</strong
                  >
                </td>
              </tr>
            </table>
          </div>

          <div
            class="font"
            style="
              text-align: center;
              margin-top: 30px;
              font-size: 10pt;
              text-transform: capitalize;
            "
          >
            This is Computer Generate Invoice No Signature Required
          </div>

          <!-- <table
            style="
              width: 100%;
              font-size: 10pt;
              color: #00364f;
              text-transform: capitalize;
            "
          >
            <tr class="font">
              <td style="width: 30%">
                <div class="row">
                  <div class="col text-truncate">In association with: &nbsp;</div>
                  <div class="col" v-if="admin">
                    <img style="width: 60px" :src="logofinder(admin)" />
                  </div>
                </div>
              </td>

              <td class="alin-right">
                <div class="logo-img" v-if="admin">
                  Powerd by: &nbsp;
                  <img style="width: 60px" src="/assets/image/vakilgiri.png" alt="" />
                </div>
              </td>
            </tr>
          </table> -->
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
    </div>
  </div>

  <!-- model paymrnt -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="paymentReciptPay"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong style="color: black">User Payment</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="clientPayServiceAmountPay()">
            <div class="row">
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Amount <small style="color: #f21000">*</small></label>
                <input
                  type="number"
                  min="1"
                  :max="dueAmt"
                  class="form-control"
                  v-model="userPaymentForm.amt"
                  placeholder="Amount"
                  required
                />
                <span id="amountPay" style="color: #f21000"></span>
              </div>
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Date <small style="color: #f21000">*</small></label>
                <input
                required
                  type="date"
                  v-model="userPaymentForm.date"
                  class="form-control"
                  placeholder="Date"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.date">{{
                          userPaymentForm.errors.error.date[0]
                        }}</span></span
                      ></small
                    >

              </div>

              <!-- <div
              v-if="userPaymentForm.paymentMode == 'Online'"
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Transection Number</label>
                <input
              
                  v-model="userPaymentForm.txn"
                  type="text"
                  class="form-control"
                  placeholder="Transection Number"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.txn">{{
                          userPaymentForm.errors.error.txn[0]
                        }}</span></span
                      ></small
                    >
              </div>

              <div   v-if="userPaymentForm.paymentMode == 'Online'"
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Tr. Reference No</label>
                <input
                  v-model="userPaymentForm.txnRefNo"
                  type="text"
                  class="form-control"
                  placeholder="Transection Reference Number"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.txnRefNo">{{
                          userPaymentForm.errors.error.txnRefNo[0]
                        }}</span></span
                      ></small
                    >
              </div> -->

              <div
                class="col-xl-12 col-md-12 col-lg-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Payment Mode <small style="color: #f21000">*</small></label>

                <select
                required
                  v-model="userPaymentForm.paymentMode"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Payment Mode...</option>
                  <option value="Cash">Cash</option>
                  <option value="Online">Online</option>
                  <option value="Cheque">Cheque</option>
                </select>
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.paymentMode">{{
                          userPaymentForm.errors.error.paymentMode[0]
                        }}</span></span
                      ></small
                    >
              </div>

              <div
                v-if="userPaymentForm.paymentMode == 'Cheque'"
                class="col-xl-12 col-md-12 col-lg-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Cheque No.</label>
                <input
                  v-model="userPaymentForm.checkno"
                  type="number"
                  class="form-control"
                  placeholder="Cheque No."
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.checkno">{{
                          userPaymentForm.errors.error.checkno[0]
                        }}</span></span
                      ></small
                    >
              </div>

              <!-- <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="userPaymentForm.paymentMode == 'Online'"
              >
                <label>Bank Name</label>
                <input
                  v-model="userPaymentForm.bank"
                  type="tel"
                  class="form-control"
                  placeholder="Bank Name"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.bank">{{
                          userPaymentForm.errors.error.bank[0]
                        }}</span></span
                      ></small
                    >
              </div>
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="userPaymentForm.paymentMode == 'Online'"
              >
                <label>Branch</label>
                <input
                  v-model="userPaymentForm.branch"
                  type="tel"
                  class="form-control"
                  placeholder="Branch"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.branch">{{
                          userPaymentForm.errors.error.branch[0]
                        }}</span></span
                      ></small
                    >
              </div>

              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="userPaymentForm.paymentMode == 'Online'"
              >
                <label>IFSC</label>
                <input
                maxlength="11"
                minlength="11"
                  v-model="userPaymentForm.ifsc"
                  type="tel"
                  class="form-control"
                  placeholder="IFSC"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.ifsc">{{
                          userPaymentForm.errors.error.ifsc[0]
                        }}</span></span
                      ></small
                    >
              </div>
              <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="userPaymentForm.paymentMode == 'Online'"
              >
                <label>Account No</label>
                <input
                @keydown="accountNoVall"
                type="tel"
                    maxlength="18"
                    minlength="9"
                  v-model="userPaymentForm.accountno"
     
                  class="form-control"
                  placeholder="Account No"
                />
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.accountno">{{
                          userPaymentForm.errors.error.accountno[0]
                        }}</span></span
                      ></small
                    >
              </div> -->

              <!-- <div
                class="col-xl-6 col-md-6 col-lg-6 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Status</label>

                <select
                  v-model="userPaymentForm.status"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option selected disabled>Select...</option>
                  <option value="paid">Paid</option>
                </select>
              </div> -->

              <div
                class="col-xl-12 col-md-12 col-lg-12 col-12"
                style="padding: 5px 5px 5px 5px"
              >
                <label>Remark</label>

                <textarea
                  v-model="userPaymentForm.notation"
                  placeholder="Remark"
                  class="form-control"
                  rows="3"
                ></textarea>
                <small style="color: red" v-if="userPaymentForm.errors"
                      ><span v-if="userPaymentForm.errors.error"
                        ><span v-if="userPaymentForm.errors.error.notation">{{
                          userPaymentForm.errors.error.notation[0]
                        }}</span></span
                      ></small
                    >
              </div>
              <!-- @click="thankyouPage" -->
              <div class="row">
                <div class="col text-end p-1">
                  <!-- <button
                    v-if="reciptData.amt"
                    type="button"
                    class="btn btn-sm text-white shadow-lg"
                    style="background-color: #f21000; margin-right: 10px"
                    @click="thankyouPage"
                  >
                    Next
                  </button> -->
                  <!-- <button
                    v-if="reciptData.amt"
                    type="button"
                    class="btn btn-sm text-white shadow-lg"
                    style="background-color: #208504; margin-right: 10px"
                    data-bs-toggle="modal"
                    data-bs-target="#paymentRecipt"
                  >
                    Payment Recipt
                  </button> -->
                  <button
                    type="submit"
                    class="btn btn-sm text-white shadow-lg"
                    style="background-color: #00364f"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <!-- edn row -->
            </div>
          </form>
        </div>
        <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
import moment from "moment";
import Banner from "../../../components/retailer/comman/Banner.vue";
import InWordsNumber from "../../../mixins/numberToinword";
// import Form from "vform";
import jQuery from "jquery";
// var _ = require("lodash");
let $ = jQuery;
export default {
  name: "ViewProjectRetailer",
  mixins: [InWordsNumber],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {

      professionaUser:{},
      netAmtInWords: '',
      dueAmt: 0,
      paidAmt: 0,
      userPaymentForm: {
        client_id: "",
        service_id: "",
        client_service_id: "",
        amt: "",
        date: "",
        paymentMode: "Cash",
        txn: "",
        txnRefNo: "",

        checkno: "",
        bank: "",
        ifsc: "",

        branch: "",
        accountno: "",
        notation: "",
        remark: "",
        status: "paid",
        errors:{}
      },
      serviceModules: [],
      certificates: [],
      upgradeModelSatffVeryfiyForm: {
        status: "cancel",
        remark: "",
      },
      upgrade_veryfication_id: null,
      taskProgress: 0,
      msgFromPublic: {
        client_service_id: null,
        type: "",
        touser_id: null,
        touser_type: "",
        remark: "",
        service_id: null,
        comment: "",
      },
      requestdocuments: [],
      loading: false,
      challans: [],
      services: [],
      project: {},
      userData: {},
      allDoccs: [],

      certificateForm: {
        service_id: null,
        client_service_id: "",
        title: "",
        note: "",
        certificateAthority: "",
        certificateNo: "",
        expireDate: "",
        date: "",
        certificate: "",

        imagePrefix: "certificate",
      },

      chalansForm: {
        imagePrefix: "challan",
        client_service_id: "",
        service_id: null,

        title: "",
        challanAthority: "",
        challanNo: "",
        date: "",
        expireDate: "",
        validity: "",
        challan: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
        thumbnail: "yes",
      },
      clientDoccumentsfrom: {
        title: "",
        attachment: "",
        remark: "",
        service_id: "",
        client_service_id: "",
      },
      documentsByRetailerAll: [],
      upgradeData: [],
      upgradeForm: {
        imagePrefix: "Upgrade",
        client_service_id: "",
        service_id: null,

        title: "",

        date: "",
        document: "",
        amt: "",
        note: "",
        remark: "",
        status: "",
        ipAddress: "",
      },

      uploadDocc_form: {
        client_service_id: null,
        service_document_id: null,
        alt: "",
        caption: "",
        serviceDocument: "",
        cwidth: "",
        cheight: "",
        cx: "",
        cy: "",
      },
      upgradeEdit: "false",
      upgradesData: [],
      extensionCertificate: "",
      msgImgError: "",
      extensionChallan: "",
      totalAmtChallan: 0,
      TasksData: [],
      Tasks: [],
      comments: [],
      commentsprivates: [],
      reciptData: {},
      paymentRecipts: [],

      extensionAtech: "",
    };
  },
  methods: {
    deleteDoccUploadByRetailer(docc){
      this.loading = true
      this.$axios
        .delete(`retailer/clientservicedocument/${docc.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res
          toast.success("Deleted successfully.", {
                autoClose: 1000,
              });
              this.loadProject();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
    validation() {
      var paidAmtuser = this.userPaymentForm.amt + this.paidAmt;
      var totle = this.project.serviceCharge + this.project.serviceChargeGst;
      if (paidAmtuser > totle) {
        $("#amountPay").html("Amout Must be less than or equal total Amount");
      } else {
        $("#amountPay").html("");
      }
    },
    paymentReciptAsign(recipt) {
      this.reciptData = recipt;
    },
    valuenullPayment() {
      this.userPaymentForm = {};
      this.userPaymentForm.errors = {};
    },
    getAmtDue(project, type) {
      var paidAmt = 0;
      for (let i = 0; i < this.paymentRecipts.length; i++) {
        paidAmt += this.paymentRecipts[i].amt;
      }
      var amt = project.serviceCharge + project.serviceChargeGst;

      if (type == "paid") {
        this.paidAmt = paidAmt;
        return paidAmt;
      }
      if (type == "due") {

        this.dueAmt = amt - paidAmt;
        return amt - paidAmt;
      }
    },
    clientPayServiceAmountPay() {
      this.userPaymentForm.client_id = this.project.client_id;
      this.userPaymentForm.client_service_id = this.project.id;
      this.userPaymentForm.service_id = this.project.service_id;
      this.loading = true;
      this.$axios
        .post(`retailer/clientpayment`, this.userPaymentForm, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          this.clientPayServiceAmount();
          this.userPaymentForm = {};

          toast.success("Save Successfully", {
            autoClose: 1000,
          });
          $("#paymentReciptPay").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error.data.message;
          this.msgError = error.response.data;
          this.userPaymentForm.errors = error.response.data;
          console.log(error.response.data);
        });
    },

    clientPayServiceAmount() {
      this.$axios
        .get(`retailer/clientpayment?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // this.reciptData = res.data.data;
          this.paymentRecipts = res.data.data.data;
          console.log(this.paymentRecipts);
        });
    },
    freshUpgreadeForm(value) {

      this.upgradeModelSatffVeryfiyForm = {};

      this.upgradeModelSatffVeryfiyForm.amt = value.amt;

      this.upgrade_veryfication_id = value.id;
    },
    upgradeModelSatffSaveData() {
      this.loading = true;
      this.$axios
        .post(
          `retailer/clientserviceupgrade/paid/${this.upgrade_veryfication_id}`,
          this.upgradeModelSatffVeryfiyForm,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res.data);

          toast.success("The Task successfully Done", {
            autoClose: 1000,
          });
          this.getUpgrade();
          $("#upgradeModelSatff").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },
    // progressForProject() {
    //   var complete = 0;
    //   for (var i = 0; i < this.Tasks.length; i++) {
    //     if (this.Tasks[i].sts == "complete") {
    //       complete += 1;
    //     }
    //   }
    //   var n = 100 / this.Tasks.length;

    //   this.taskProgress = n * complete;
    //   return this.taskProgress;
    // },
    progressForProject() {
      var complete = 0;
      for (var i = 0; i < this.Tasks.length; i++) {
        if (this.Tasks[i].status == "complete") {
          complete += 1;
        }
      }
      var n = 100 / this.Tasks.length;

      this.taskProgress = n * complete;
      return this.taskProgress;
    },
    // comment area
    getAllComments() {
      this.loading = true;
      this.$axios
        .get(`retailer/clientservicecomment?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.comments = res.data.data.data;
        });

      this.$axios
        .get(
          `retailer/clientservicecomment?type=private&client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.commentsprivates = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    privateTypeSand() {
      console.log(this.userValue);
      if (this.userValue == 1) {
        this.msgFromPublic.touser_id = 1;
        this.msgFromPublic.touser_type = "superAdmin";
      }
      if (this.userValue.role_id == 2) {
        this.msgFromPublic.touser_id = this.userValue.id;
        this.msgFromPublic.touser_type = "staff";
      }


      console.log(this.msgFromPublic);
    },

    sandMessagePublick(type) {
      var userData = {};
      userData = JSON.parse(localStorage.getItem("userData"));
      console.log(userData);
      // if (type == "private") {
      //   this.msgFromPublic.touser_id = userData.id;
      //   if (userData.role) {
      //     this.msgFromPublic.touser_type = userData.role.name;
      //   }
      // }

      this.msgFromPublic.type = type;
      this.msgFromPublic.client_service_id = this.project.id;
      this.msgFromPublic.service_id = this.project.service_id;
      console.log(this.project);
      console.log(this.msgFromPublic);

      this.loading = true;
      this.$axios
        .post(`retailer/clientservicecomment`, this.msgFromPublic, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("Comment Sand Successfully", {
            autoClose: 1000,
          });
          this.msgFromPublic = {};
          this.getAllComments();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response.data.error.service_id[0]);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error.service_id[0],
          });
        });
    },
    accountNoVall(e) {
  const inputValue = e.key;

  // Allowing only Backspace key
  if (inputValue === 'Backspace' || inputValue === 'Delete') {
    // Perform your specific action for the Backspace key
    console.log('Backspace key pressed!');
  } else {
    // Blocking other keys, including Delete, non-word characters, and alphabets
    if (/^\W$/.test(inputValue) || /[a-zA-Z]/.test(inputValue)) {
      this.error = true;
      e.preventDefault();
    } else {
      this.error = false;
    }
  }
},
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    openWindow(url) {
      window.open(`${url}`);
    },
  
    deleteChallans(value) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`retailer/clientservicechallan/${value.id}`, {
                headers: {
                  Authorization: "Bearer " + localStorage.accessToken,
                },
              })
              .then((res) => {
                res;

                toast.success("Challan Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.getChalans();
              });
          }
        });
    },
    loadTask() {




      this.$axios
        .get(
          `retailer/clientserviceprofessionalstep?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          this.Tasks = res.data.data.data;
        });




    },
    findImgUsedr(user, user_type) {
      var img = "";
      if(user_type == 'superAdmin'){
        user_type = 'superadmin'
      }
      if (user.photo != null) {
        img =
          this.$store.state.imgUrl +
          "/" +
          user_type +
          "/" +
          user.id +
          "/thumbs/" +
          user.photo;
      } else {
        img = this.$store.state.placeholderImg;
      }
      return img;
    },
    saveTask(event, task) {
      var form = {};
      if (event.target.checked == true) {
        console.log(event.target.checked);
        console.log(task);

        form.status = "complete";
        form.remark = "";
      }
      if (event.target.checked == false) {
        form.status = "pendding";
        form.remark = "";
      }
      this.loading = true;
      this.$axios
        .post(`retailer/clientserviceprofessionalstep/status/${task.id}`, form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);

          toast.success("The Task successfully Done", {
            autoClose: 1000,
          });
          this.getProfessionalTaskCompleted();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        });
    },

    getChalans() {
      var amt = 0;
      this.$axios
        .get(`retailer/clientservicechallan?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          this.challans = res.data.data.data;
          for (var i = 0; i < this.challans.length; i++) {
            amt += parseInt(this.challans[i].amt);
          }

          this.totalAmtChallan = amt;
        });
    },
    uploadUpgrade(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.upgradeForm.document = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#uploadUpgrade").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadChallan(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.chalansForm.challan = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#challan").value;
        this.extensionChallan = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    UploadCertificate(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        // this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
        this.certificateForm.certificate = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#certificate").value;
        this.extensionCertificate = fileName.split(".").pop();

        // if (this.extensionChallan == "pdf") {
        //   this.adharFrontImg = "/assets/image/pdf.png";
        // }

        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    createChalans() {
      this.chalansForm.client_service_id = this.project.id;
      this.chalansForm.service_id = this.project.service_id;

      // console.log(this.chalansForm);

      const formData = new FormData();
      const keys = Object.keys(this.chalansForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.chalansForm[keys[n]] !== null) {
          formData.append(keys[n], this.chalansForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("retailer/clientservicechallan", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getChalans();
          $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // res
          console.log(error.response);
        });
    },
    atachmentUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Image Size Too Big 2MB";

        // return false;
        this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: this.msgImgError,
          })


      } else {
        var fileName;
        fileName = document.querySelector("#file-input2").value;
        this.extensionAtech = fileName.split(".").pop();
        if (
          this.extensionAtech == "png" ||
          this.extensionAtech == "jpg" ||
          this.extensionAtech == "jpeg" ||
          this.extensionAtech == "pdf" ||
          this.extensionAtech == "PNG" ||
          this.extensionAtech == "JPG" ||
          this.extensionAtech == "JPEG" ||
          this.extensionAtech == "PDF"
        ) {
          // this.atchImg = URL.createObjectURL(e.target.files["0"]);
          this.clientDoccumentsfrom.attachment = e.target.files["0"];

          this.clientServiceDoccumentRequestPost();
          console.log(this.clientDoccumentsfrom);
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG, PDF";
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: this.msgImgError,
          })
        }
      }


    },
    clientServiceDoccumentRequestGet() {
      this.loading = true;
      this.$axios
        .get(
          `retailer/clientservicerequestdocument?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.requestdocuments = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    assignIDforReply(doc) {
      this.clientDoccumentsfrom = doc;
      
    },
    clientServiceDoccumentRequestPost() {
      // console.log(this.chalansForm);
      this.clientDoccumentsfrom.remark = "";
      const formData = new FormData();
      const keys = Object.keys(this.clientDoccumentsfrom);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.clientDoccumentsfrom[keys[n]] !== null) {
          formData.append(keys[n], this.clientDoccumentsfrom[keys[n]]);
        }
      }

      this.loading = true;
      this.$axios
        .post(
          `retailer/clientservicerequestdocument/${this.clientDoccumentsfrom.id}?_method=PUT`,
          formData,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The Request was sent successfully", {
            autoClose: 1000,
          });
          this.clientServiceDoccumentRequestGet();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);

          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
  
            text: error.response.data.error,
          });
        });
    },
    getUpgrade() {
      this.loading = true;
      this.$axios
        .get(`retailer/clientserviceupgrade?client_service_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log("*************************");
          // console.log(res.data.data.data);
          this.upgradesData = res.data.data.data;
          // console.log("*************************");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    createUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("retailer/clientserviceupgrade", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    upgradeDelete(upgrade) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .delete(`retailer/clientserviceupgrade/${upgrade.id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Deleted Succesfully", {
                  autoClose: 1000,
                });
                this.getUpgrade();
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                error;
              });
          }
        });
    },

    upgradeDataAsign(upgrade) {
      // console.log(upgrade);
      this.upgradeEdit = "true";
      this.upgradeForm.id = upgrade.id;
      this.upgradeForm.client_service_id = upgrade.client_service_id;
      this.upgradeForm.service_id = upgrade.service_id;
      this.upgradeForm.title = upgrade.title;
      this.upgradeForm.date = upgrade.date;
      this.upgradeForm.amt = upgrade.amt;
      this.upgradeForm.note = upgrade.note;
      // console.log(this.upgradeForm);
    },
    updateUpgrade() {
      this.upgradeForm.client_service_id = this.project.id;
      this.upgradeForm.service_id = this.project.service_id;

      // console.log(this.upgradeForm);

      const formData = new FormData();
      const keys = Object.keys(this.upgradeForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.upgradeForm[keys[n]] !== null) {
          formData.append(keys[n], this.upgradeForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post(
          `retailer/clientserviceupgrade/${this.upgradeForm.id}?_method=PUT`,
          formData,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          // console.log(res.data);
          res;

          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          this.getUpgrade();
          this.upgradeForm = {};
          $("#upgradeModel").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },

    createCertificateByPrfesional() {
      this.certificateForm.client_service_id = this.project.id;
      this.certificateForm.service_id = this.project.service_id;
      // console.log(this.certificateForm);

      const formData = new FormData();
      const keys = Object.keys(this.certificateForm);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.certificateForm[keys[n]] !== null) {
          formData.append(keys[n], this.certificateForm[keys[n]]);
        }
      }
      this.loading = true;
      this.$axios
        .post("retailer/clientservicecertificate", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          toast.success("The form was sent successfully", {
            autoClose: 1000,
          });
          // this.getChalans();
          // $("#challansModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          console.log(error.response);
        });
    },
    clientserviceDocumentLoad() {
      this.$axios
        .get(
          `retailer/clientservicedocument?client_service_id=${this.$route.params.id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log('-------clientservice_id--------')
          console.log(res.data.data.data);
          this.documentsByRetailerAll = res.data.data.data;
        });
    },
    uploadDocuments(event, docc) {

var extension = ""

      var msgImgError = "";
      if (event.target.files["0"].size > 2 * (1024 * 1024)) {
     
        event.preventDefault();
        msgImgError = "TAN File Size Too Big 2MB";

        
      } else {
        var fileName;
        fileName = document.querySelector(`#doccUpload${docc.id}`).value;
        extension = fileName.split(".").pop();
 
        if (
          extension == "pdf"  ||
          extension == "jpg"  ||
          extension == "jpeg" ||
          extension == "png"  ||
          extension == "PDF"  ||
          extension == "JPG"  ||
          extension == "JPEG" ||
          extension == "PNG"
        ) {
          
          this.uploadDocc_form.serviceDocument = event.target.files["0"];
          // this.uploadDoccuments();
        } else {
          msgImgError = "File type must JPG ,JPEG , PNG ";
        }
      }


      if(msgImgError){
        this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" +msgImgError + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
      }else{
        this.uploadDocc_form.service_document_id = docc.id;
      this.uploadDocc_form.client_service_id = this.$route.params.id;
      this.uploadDocc_form.alt = docc.document;
      this.uploadDocc_form.caption = docc.document;

      // console.log('-------------------------------------------------------------------------------')

      const formData = new FormData();
      const keys = Object.keys(this.uploadDocc_form);
      for (let n = 0; n < keys.length; n += 1) {
        if (this.uploadDocc_form[keys[n]] !== null) {
          formData.append(keys[n], this.uploadDocc_form[keys[n]]);
        }
      }
      this.loading = true
      this.$axios
        .post("retailer/clientservicedocument", formData, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // console.log(res.data);
          res;
          // alert(res.data)
          toast.success("Upload successfully.", {
                autoClose: 1000,
              });

              document.querySelector(`#doccUpload${docc.id}`).value = "";
              this.loadProject();
          // this.LoadDocuments(this.project.service)
          // this.clientserviceDocumentLoad();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      }

     
        
    },
    LoadDocuments(service) {
      var service_id = service.id;
      this.$axios
        .get(`retailer/servicedocument?service_id=${service_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          //   console.log(res.data)
          this.allDoccs = res.data.data.data;
          this.allDoccs = res.data.data.data;
        });
    },
    loadCertificates() {
      this.$axios
        .get(
          `retailer/clientservicecertificate?client_service_id=${this.$route.params.id}`,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          //   console.log(res.data)
          this.certificates = res.data.data.data;
        });
    },
    loadUserApi() {
      this.$axios
        .post(
          `retailer/user`,
          {},
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          // console.log(res.data)
          this.userData = res.data;
        });
    },
    openWindowClientDoccRequest(docc) {
      var url = "";
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/thumbs/" +
          docc.attachment;
      }

      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        this.project.id +
        "/thumbs/" +
        docc.attachment
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          this.project.id +
          "/" +
          docc.attachment;
      }

      window.open(`${url}`);
    },
    downloadImg(link) {
      var url;
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/thumbs/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/thumbs/" +
          link.document;
      }
      if (
        this.$store.state.imgUrl +
        "/clientservice/" +
        link.client_service_id +
        "/" +
        link.document
      ) {
        url =
          this.$store.state.imgUrl +
          "/clientservice/" +
          link.client_service_id +
          "/" +
          link.document;
      }

      window.open(`${url}`);
    },
    loadProject() {
      this.$axios
        .get(`retailer/clientservice/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.project = res.data.data;
this.Tasks = this.project.service.service_steps
this.conertNumberToWords(this.project.serviceCharge+this.project.serviceChargeGst);
console.log('+++++++++++++++++++++++++++++++++++++++++++++++')
console.log(this.Tasks)
          this.loadModules();
          this.loadTask();
        });
    },
    loadModules() {
      var mod = JSON.parse(this.project.serviceModules);
      this.$axios
        .post(
          `/retailer/servicemodule`,
          { serviceModulesIds: mod },
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.serviceModules = res.data.data;
        });
    },
    loadPageTitle() {
      document.title = this.$store.state.retailerPageTitles.clientsServiceView
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadProject();
      this.loadUserApi();
      this.getChalans();
      this.getUpgrade();
      this.clientserviceDocumentLoad();
      this.professionaUser  = JSON.parse(localStorage.getItem("userData"));
      this.loadCertificates();
      this.clientServiceDoccumentRequestGet();
      this.getAllComments();
      this.clientPayServiceAmount();
             
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

h4 {
  font-size: 1rem;
}
</style>
